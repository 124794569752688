import { tokens } from '../tokens';

export const en = {
   [tokens.common.languageChanged]: 'Language changed',
   [tokens.nav.checkout]: 'Check Out',
   [tokens.nav.setup]: 'Setup',
   [tokens.nav.profile]: 'Profile',
   [tokens.nav.myMigration]: 'My Migrations',
   [tokens.nav.createMigration]: 'Create Migration',
   [tokens.nav.allIn]: 'All-In-One Migration',
   [tokens.nav.topUpBalance]: 'Top Up Balance',
   [tokens.nav.orderHistory]: 'Order History',
   [tokens.nav.downloadInvoice]: 'Download Invoice',
   [tokens.nav.sla]: 'Migration SLA',
   [tokens.nav.logout]: 'Log Out',
   [tokens.nav.customMigration]: 'Custom Migration',
   [tokens.nav.migrationCustomization]: 'Migration Customization',
   [tokens.nav.premiumSubscription]: 'Premium Subscription',
   [tokens.nav.premiumSupport]: 'Premium Support',
   [tokens.nav.helpCenter]: 'Help Center',
   [tokens.nav.reportProblem]: 'Report a Problem',
   [tokens.nav.paymentGuide]: 'Payment Guide',
   [tokens.nav.myTickets]: 'My Support Tickets',
   [tokens.nav.createNewMigration]: 'Create New Migration',
   [tokens.nav.continueMigration]: 'Continue Migration',
   [tokens.nav.recentMigration]: 'Recent Migration',
   [tokens.nav.remigrate]: 'Remigrate',
   [tokens.nav.smartUpdate]: 'Smart Update',
   [tokens.nav.viewProgress]: 'View Progress',
   [tokens.nav.new]: 'New',
   [tokens.nav.full]: 'Full',
   [tokens.nav.recent]: 'Recent',
   [tokens.nav.migrationSetup]: 'Migration Setup',
   [tokens.nav.migrationConfiguration]: 'Migration Configuration',
   [tokens.nav.demoMigrationCompleted]: 'Demo Migration Completed',
   [tokens.nav.migrationConfirmation]: 'Migration Confirmation',
   [tokens.nav.migrationRunning]: 'Migration Running',
   [tokens.nav.migrationCompleted]: 'Migration Completed',
   [tokens.nav.availableActions]: 'Available Actions',
   [tokens.nav.fullMigration]: 'Full Migration',
   [tokens.nav.restartMigrationFailed]: 'Restart Migration Failed',
   [tokens.nav.restartMigrationSucceeded]: 'Restart Migration Succeeded',
   [tokens.nav.startMigrationFailed]: 'Start Migration Failed',
   [tokens.nav.pauseMigrationFailed]: 'Pause Migration Failed',
   [tokens.nav.fullMigrationIsRunning]: 'Full Migration Is Running',
   [tokens.nav.instruction]: 'Instruction',
   [tokens.nav.getAdditionalOptionsFailed]: 'Getting Additional Options Failed',
   [tokens.nav.warning]: 'Warning',
   [tokens.nav.enter]: 'Enter',
   [tokens.nav.howToGet]: 'How to Get',
   [tokens.nav.key]: 'Key',
   [tokens.nav.howToGetAPIKeyFromNeto]: 'How To Get API Key from Neto',
   [tokens.nav.autoUploadConnector]: 'I want to automatically upload the connector',
   [tokens.nav.manualUploadConnector]: 'I want to manually upload the connector',
   [tokens.nav.sourceCartType]: 'Source Cart Type',
   [tokens.nav.targetCartType]: 'Target Cart Type',
   [tokens.nav.sourceCartURL]: 'Source Cart URL',
   [tokens.nav.targetCartURL]: 'Target Cart URL',
   [tokens.nav.sourceCartSetup]: 'Source Cart Setup',
   [tokens.nav.targetCartSetup]: 'Target Cart Setup',
   [tokens.nav.completed]: 'Completed',
   [tokens.nav.confirmation]: 'Confirmation',
   [tokens.nav.fullMigrationCompleted]: 'Full Migration Is Completed',
   [tokens.nav.yourDemoMigrationCompleted]: 'Your Demo Migration Is Completed',
   [tokens.nav.migratingCompleted]: 'Migrating Completed',
   [tokens.nav.thisAdditionFieldIsRequired]: 'This addition field is required',
};
