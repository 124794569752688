const uploadOptions = [
   {
      name: 'KITLNKS',
      cartTypes: ['volusion'],
      entity: 'products',
      field: 'file_kit_links',
      instructionKey: 'import_kitlnks_data',
   },
   {
      name: 'KITS',
      cartTypes: ['volusion'],
      entity: 'products',
      field: 'file_kits',
      instructionKey: 'import_blogs_data',
   },
   {
      name: 'Option Categories',
      cartTypes: ['volusion'],
      entity: 'products',
      field: 'file_option_categories',
      instructionKey: 'import_optionCategories_data',
   },
   {
      name: 'Options',
      cartTypes: ['volusion', 'corecommerce'],
      entity: 'products',
      field: 'file_options',
      instructionKey: 'import_options_data',
   },
   {
      name: 'Products',
      cartTypes: ['volusion', 'jimdo', 'epages', 'ekm', 'ekmpowershop', 'zoey', 'corecommerce'],
      entity: 'products',
      field: 'file_products',
      instructionKey: 'import_products_data',
   },
   {
      name: 'Product Photo',
      cartTypes: ['corecommerce'],
      entity: 'products',
      field: 'file_personalizations',
      instructionKey: 'import_personalizations_data',
   },
   {
      name: 'Personalizations',
      cartTypes: ['corecommerce'],
      entity: 'products',
      field: 'file_product_images',
      instructionKey: 'import_product_images_data',
   },
   {
      name: 'Category Product',
      cartTypes: ['epages'],
      entity: 'products',
      field: 'file_categories_products',
      instructionKey: 'import_products_data',
   },
   {
      name: 'Product Price Group',
      cartTypes: ['epages'],
      entity: 'products',
      field: 'file_product_price_group',
      instructionKey: 'import_product_price_group_data',
   },
   {
      name: 'Product Attributes',
      cartTypes: ['epages'],
      entity: 'products',
      field: 'file_products_attribute',
      instructionKey: 'import_product_attribute_data',
   },
   {
      name: 'Catalog',
      cartTypes: ['yahoostore'],
      entity: 'products',
      field: 'file_catalog',
      instructionKey: 'import_catalog',
   },
   {
      name: 'Object Information',
      cartTypes: ['yahoostore'],
      entity: 'products',
      field: 'file_objinfo',
      instructionKey: 'import_object',
   },
   {
      name: 'Reviews',
      cartTypes: ['volusion', 'shopify', 'shift4shop', 'corecommerce'],
      entity: 'reviews',
      field: 'file_reviews',
      instructionKey: 'import_reviews_data',
   },
   {
      name: 'Categories',
      cartTypes: ['volusion', 'epages', 'ekm', 'ekmpowershop', 'zoey', 'corecommerce'],
      entity: 'categories',
      field: 'file_categories',
      instructionKey: 'import_categories_data',
   },
   {
      name: 'Customers',
      cartTypes: ['volusion', 'epages', 'ekm', 'ekmpowershop', 'zoey', 'corecommerce'],
      entity: 'customers',
      field: 'file_customers',
      instructionKey: 'import_customers_data',
   },
   {
      name: 'Orders',
      cartTypes: [
         'volusion',
         'jimdo',
         'yahoostore',
         'epages',
         'ekm',
         'ekmpowershop',
         'bigcartel',
         'zoey',
         'corecommerce',
      ],
      entity: 'orders',
      field: 'file_orders',
      instructionKey: 'import_orders_data',
   },
   {
      name: 'OrderDetails',
      cartTypes: ['volusion'],
      entity: 'orders',
      field: 'file_order_details',
      instructionKey: 'import_orderdetails_data',
   },
   {
      name: 'Pages',
      cartTypes: ['volusion'],
      entity: 'pages',
      field: 'file_pages',
      instructionKey: 'import_pages_data',
   },
   {
      name: 'Blogs',
      cartTypes: ['volusion'],
      entity: 'blogs',
      field: 'file_blogs',
      instructionKey: 'import_blogs_data',
   },
   {
      name: 'Discounts',
      cartTypes: ['volusion'],
      entity: 'coupons',
      field: 'file_coupons',
      instructionKey: 'import_discounts_data',
   },
   {
      name: 'Discount_Applyto',
      cartTypes: ['volusion'],
      entity: 'coupons',
      field: 'file_coupon_applyto',
      instructionKey: 'import_discounts_applyto_data',
   },
];

const additionalOptions = [
   {
      name: 'Clear current data on Target Store before Migration',
      field: 'clear_shop',
   },
   {
      name: 'Migrate Source Store categories into Shopify Smart Collections',
      field: 'smart_collection',
   },
   {
      name: `Migrate customers' passwords`,
      field: 'cus_pass',
   },
   {
      name: 'Ignore migrate image to Target store',
      field: 'ignore_image',
   },
   {
      name: 'Transfer images from Categories, Product descriptions to Target Store',
      field: 'img_des',
   },
   {
      name: 'Migrate guest newsletter subscribers',
      field: 'newsletters',
   },
   {
      name: 'Preserve Order IDs on Target Store',
      field: 'pre_ord',
   },
   {
      name: 'Preserve Customer IDs on Target Store',
      field: 'pre_cus',
   },
   {
      name: 'Preserve Product IDs on Target Store',
      field: 'pre_prd',
   },
   {
      name: 'Migrate customer shopping cart',
      field: 'quotes',
   },
   {
      name: 'Migrate categories and products SEO URLs',
      field: 'seo',
   },
   {
      name: 'Create the 301 redirects on your target store after migration',
      field: 'seo_301',
   },
   {
      name: 'Strip HTML from category, product names',
      field: 'strip_html',
   },
];

const entities = [
   { name: 'Products', field: 'products', summary_instruction_key: 'products_summary' },
   {
      name: 'Customers',
      field: 'customers',
      summary_instruction_key: 'customers_summary',
   },
   { name: 'Orders', field: 'orders', summary_instruction_key: 'orders_summary' },
   {
      name: 'Categories',
      field: 'categories',
   },
   { name: 'Manufactures', field: 'manufacturers' },
   { name: 'Taxes', field: 'taxes' },
   { name: 'CMS pages', field: 'pages' },
   { name: 'Blog posts', field: 'blogs' },
   { name: 'Coupons', field: 'coupons' },
   { name: 'Reviews', field: 'reviews' },
];

const checkboxFields = [
   { label: 'Products', field: 'products', isParent: true },
   { label: 'Reviews', field: 'reviews', isChild: true, parent: 'products' },
   { label: 'Categories', field: 'categories', isChild: true, parent: 'products' },
   { label: 'Taxes', field: 'taxes', isChild: true, parent: 'products' },
   { label: 'Manufactures', field: 'manufacturers', isChild: true, parent: 'products' },
   { label: 'Customers', field: 'customers', isParent: true },
   { label: 'Orders', field: 'orders', isChild: true, parent: 'customers' },
   { label: 'Pages', field: 'pages' },
   { label: 'Blog Posts', field: 'blogs' },
   { label: 'Coupons', field: 'coupons' },
];

export { entities, additionalOptions, uploadOptions, checkboxFields };
